import '@/style.less'
import Inputmask from "inputmask";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';



gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);

document.addEventListener('DOMContentLoaded', ()=>{
	const currentUrl = window.location.href;
	console.log(currentUrl);
	
	let scroll_menu = document.querySelector('#scroll_menu');
	let scroll_menu_show_h = 180;
	document.addEventListener('scroll', function(){
		if (window.scrollY > scroll_menu_show_h) {
			scroll_menu.classList.add('fixed');
		} else {
			scroll_menu.classList.remove('fixed');
		}
	})

	// Мобильное меню
	let header = document.querySelector('header.header')
    let burderBtn = document.querySelector('.header .header__burger-btn')
    let mobileMenu = document.querySelector('.mobile-menu')
   
    if(mobileMenu){
        mobileMenu.style.zIndex = '-1'
        burderBtn.addEventListener('click', function(e) {
            e.preventDefault();
            this.classList.toggle('active')
            mobileMenu.classList.toggle('open');
            mobileMenu.classList.toggle('close');
			header.classList.toggle('menu-open');
            if(mobileMenu.classList.contains('close')) {
                setTimeout(function(){
                    mobileMenu.style.zIndex = '-1'
                },1400) // время всей анимации закрытия
            } else {
                mobileMenu.style = ''
            }
        });
    }

	//   Аккордион
    const accordionItems = document.querySelectorAll(".accordion-item");
	if(accordionItems.length){
		accordionItems.forEach((item) => {
			const header = item.querySelector(".accordion-item__header");
			const body = item.querySelector(".accordion-item__body");
			const bodyScrollHeight = body.scrollHeight;
			item.classList.add("collapsed");

			header.addEventListener("click", () => {
				accordionItems.forEach((accItem) => {
					const accBody = accItem.querySelector(".accordion-item__body");
					if (accItem !== item && !accItem.classList.contains("collapsed")) {
						accBody.classList.add("collapsed");
						accItem.classList.add("collapsed");
						accBody.style.height = "0";
					}
				});

				item.classList.toggle("collapsed");
				if (item.classList.contains("collapsed")) {
					body.style.height = "0";
				} else {
					body.style.height = bodyScrollHeight + "px";
				}
			});
		});
	}

	// Inputmask
	let phoneInputs = document.querySelectorAll("input[type='tel']");
	let im = new Inputmask("+7 ( 999 ) 999 99 99");
	im.mask(phoneInputs);

	// if(window.innerWidth < 1024) {

		// swiper projects-block
		const swiper = new Swiper('.swiper-projects-block', {
			modules: [Navigation],
			direction: 'horizontal',
			loop: true,
			slidesPerView: 1,
			slidesPerGroup: 1,
			speed: 400,
			spaceBetween: 32,
			breakpoints: {
				// 959: {
				// 	spaceBetween: 25,
				// },
				// 480: {
				// 	slidesPerView: 1.2,
				// },
				// 320: {
				// 	slidesPerView: 1.08,
				// }
			},
			navigation: {
				nextEl: '.projects-block .swiper-wrap .swiper-button-next',
				prevEl: '.projects-block .swiper-wrap .swiper-button-prev',
			},
		});
		console.log(document.querySelector('.projects-block .swiper-wrap .swiper-button-next'))
		document.querySelector('.projects-block .swiper-wrap .swiper-button-next').addEventListener('click', ()=>{
			console.log('123')
		})

	// } else {
	// 	gsap.to(".project-card__title", { rotation: 360, x: 100, duration: 2 });
	// }


	// подчеркивание ссылок  (на потом оставил)
	// let servicesLinks = document.querySelectorAll('.anim-link')
	// servicesLinks.forEach(function(link){

	// 	link.addEventListener('mouseenter', function() {
	// 		this.classList.remove('mouseleave')
	// 		this.classList.add('mouseover')
	// 	})
		
	// 	link.addEventListener('mouseleave', function() {
	// 		this.classList.remove('mouseover')
	// 		this.classList.add('mouseleave')
	// 		setTimeout(function(){
	// 			link.classList.remove('mouseleave')
	// 			link.classList.remove('mouseover')
	// 		},300)
	// 	})

	// })

	
})

